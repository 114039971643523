import Button from 'components/button';
import { FullScreenLoader } from 'components/loader';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useUpdateITHCVOutletSprinklerMutation } from 'store/devices/devicesApi';
import { selectDeviceSelected } from 'store/devices/devicesSlice';
import { OutletSprinkler } from 'utils/device';
import { ITHConfigInput } from './ITHConfigInput';

type ITHCVState = Record<
  string, // id
  OutletSprinkler
>;

function ITHConfigTypeSalidasCamasAspersores() {
  const deviceSelected = useSelector(selectDeviceSelected);
  const { serialNumber = '', outletSprinklers = [] } = deviceSelected || {};
  const [updateITHCVOutletSprinkler, { isLoading: isUpdateITHCVOutletSprinklerLoading }] =
    useUpdateITHCVOutletSprinklerMutation();

  const [state, setState] = useState<ITHCVState>({});

  const outletSprinklersToUpdate = outletSprinklers?.filter((outletSprinkler: OutletSprinkler) => {
    const localOutletSprinkler = state[outletSprinkler.id];
    return (
      outletSprinkler.electroValve !== localOutletSprinkler?.electroValve ||
      outletSprinkler.pump !== localOutletSprinkler?.pump
    );
  });
  const hasChanges = outletSprinklersToUpdate.length > 0;

  const resetState = useCallback(() => {
    const localState = outletSprinklers?.reduce((acc: ITHCVState, ithMode: OutletSprinkler) => {
      acc[ithMode.id] = ithMode;
      return acc;
    }, {});
    setState(localState);
  }, [outletSprinklers]);

  const onSave = useCallback(() => {
    outletSprinklersToUpdate.forEach((outletSprinkler: OutletSprinkler) => {
      const localOutletSprinkler = state[outletSprinkler.id];
      updateITHCVOutletSprinkler({
        serialNumber,
        ...localOutletSprinkler,
      });
    });
  }, [serialNumber, state, outletSprinklersToUpdate, updateITHCVOutletSprinkler]);

  useEffect(() => {
    resetState();
  }, [resetState]);

  return (
    <div className={'flex flex-col gap-4'}>
      {isUpdateITHCVOutletSprinklerLoading && <FullScreenLoader />}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">
        {outletSprinklers?.map((outletSprinkler) => (
          <div className="flex flex-col w-full gap-4 p-4 bg-neutral-900 rounded-md">
            <h3 className="text-lg font-semibold">Sector {outletSprinkler.id}</h3>
            <div className="w-full grid grid-cols-2 gap-2 sm:gap-6 md:gap-8">
              <ITHConfigInput
                label="Elect Valv"
                value={state[outletSprinkler.id]?.electroValve}
                onChange={(event) => {
                  setState({
                    ...state,
                    [outletSprinkler.id]: {
                      ...state[outletSprinkler.id],
                      electroValve: Number(event.target.value),
                    },
                  });
                }}
              />
              <ITHConfigInput
                label="Bomba"
                value={state[outletSprinkler.id]?.pump}
                onChange={(event) => {
                  setState({
                    ...state,
                    [outletSprinkler.id]: {
                      ...state[outletSprinkler.id],
                      pump: Number(event.target.value),
                    },
                  });
                }}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="bg-background-black  px-4 py-3 justify-end flex gap-4 sm:px-6">
        <Button variant="text-danger" onClick={resetState} disabled={!hasChanges}>
          Cancelar
        </Button>
        <Button variant="fill" onClick={onSave} disabled={!hasChanges}>
          Guardar cambios
        </Button>
      </div>
    </div>
  );
}

export default ITHConfigTypeSalidasCamasAspersores;
