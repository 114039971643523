import Button from 'components/button';
import { FullScreenLoader } from 'components/loader';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useUpdateITHCVSectorMutation } from 'store/devices/devicesApi';
import { selectDeviceSelected } from 'store/devices/devicesSlice';
import { ITHCVSector } from 'utils/device';
import { ITHConfigClock } from './ITHConfigClock';
import { ITHConfigInput } from './ITHConfigInput';

function ITHConfigTypeSector({ sectorId }: { sectorId: number }) {
  const deviceSelected = useSelector(selectDeviceSelected);
  const { serialNumber = '', sectors = [] } = deviceSelected || {};
  const [updateITHCVSector, { isLoading: isUpdateITHCVSectorLoading }] = useUpdateITHCVSectorMutation();

  const [state, setState] = useState<ITHCVSector | null>(null);
  const stateTimersObj = state?.timers?.reduce((acc, timer) => {
    acc[timer.id] = timer;
    return acc;
  }, {} as Record<string, ITHCVSector['timers'][0]>);

  const targetSector = sectors.find((sector) => sector.id === sectorId) || null;

  const hasChanges = targetSector?.timers?.some((timer) => {
    return (
      timer.on !== stateTimersObj?.[timer.id]?.on ||
      timer.off !== stateTimersObj?.[timer.id]?.off ||
      timer.cycle !== stateTimersObj?.[timer.id]?.cycle ||
      timer.ith !== stateTimersObj?.[timer.id]?.ith ||
      timer.mode !== stateTimersObj?.[timer.id]?.mode
    );
  });

  const resetState = useCallback(() => {
    setState(targetSector);
  }, [targetSector]);

  const onSave = useCallback(() => {
    if (state && hasChanges) {
      updateITHCVSector({
        serialNumber,
        ...state,
      });
    }
  }, [hasChanges, serialNumber, state, updateITHCVSector]);

  useEffect(() => {
    resetState();
  }, [resetState]);

  return (
    <div className={'flex flex-col gap-4'}>
      {isUpdateITHCVSectorLoading && <FullScreenLoader />}
      {targetSector?.timers.map((targetTimer) => (
        <div
          key={`timer - ${targetTimer.id}`}
          className="flex items-center flex-wrap lg:flex-nowrap gap-2 sm:gap-6 md:gap-8"
        >
          <div className="w-full sm:w-auto">
            <ITHConfigClock
              timerId={Number(targetTimer.id)}
              start={stateTimersObj?.[targetTimer.id]?.on}
              stop={stateTimersObj?.[targetTimer.id]?.off}
              onChange={({ start, stop }) => {
                if (!state) return;
                setState({
                  ...state,
                  timers: state.timers.map((timer) => {
                    if (timer.id === targetTimer.id) {
                      return {
                        ...timer,
                        on: start,
                        off: stop,
                      };
                    }
                    return timer;
                  }),
                });
              }}
            />
          </div>
          <div className="grid grid-cols-4 gap-2 sm:gap-6 md:gap-8">
            <ITHConfigInput
              label="Ciclo"
              value={stateTimersObj?.[targetTimer.id]?.cycle}
              onChange={(event) => {
                if (!state) return;
                setState({
                  ...state,
                  timers: state.timers.map((timer) => {
                    if (timer.id === targetTimer.id) {
                      return {
                        ...timer,
                        cycle: Number(event.target.value),
                      };
                    }
                    return timer;
                  }),
                });
              }}
            />
            <ITHConfigInput
              label="ITH"
              value={stateTimersObj?.[targetTimer.id]?.ith}
              onChange={(event) => {
                if (!state) return;
                setState({
                  ...state,
                  timers: state.timers.map((timer) => {
                    if (timer.id === targetTimer.id) {
                      return {
                        ...timer,
                        ith: Number(event.target.value),
                      };
                    }
                    return timer;
                  }),
                });
              }}
            />
            <ITHConfigInput
              label="Modo EV"
              value={stateTimersObj?.[targetTimer.id]?.mode}
              onChange={(event) => {
                if (!state) return;
                setState({
                  ...state,
                  timers: state.timers.map((timer) => {
                    if (timer.id === targetTimer.id) {
                      return {
                        ...timer,
                        mode: Number(event.target.value),
                      };
                    }
                    return timer;
                  }),
                });
              }}
            />
            {/*     <ITHConfigInput
              label="Conjunto"
              onChange={(event) => {
                // Update configObjRef.current
                configObjRef.current = {
                  ...configObjRef.current,
                  [sectorId]: {
                    ...configObjRef.current[sectorId],
                    timers: configObjRef.current[sectorId]?.timers?.map((timer, index) => {
                      if (index === timerId) {
                        return {
                          ...timer,
                          cycle: Number(event.target.value),
                        };
                      }
                      return timer;
                    }),
                  },
                };
                setIsCancelEnabled(true);
                setIsSaveEnabled(true);
              }}
            /> */}
          </div>
        </div>
      ))}
      <div className="bg-background-black  px-4 py-3 justify-end flex gap-4 sm:px-6">
        <Button variant="text-danger" onClick={resetState} disabled={!hasChanges}>
          Cancelar
        </Button>
        <Button variant="fill" onClick={onSave} disabled={!hasChanges}>
          Guardar cambios
        </Button>
      </div>
    </div>
  );
}

export default ITHConfigTypeSector;
