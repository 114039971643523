import { FormItem, Input } from 'design-system/input';

export const ITHConfigClockRango = ({
  labelOn,
  labelOff,
  onChange,
  start,
  stop,
}: {
  labelOn: string;
  labelOff: string;
  start?: number;
  stop?: number;
  onChange?: (changes: any) => void;
}) => {
  return (
    <div className="w-full grid grid-cols-2 gap-2 sm:gap-6 md:gap-8">
      <div className="min-w-[80px]">
        <FormItem label={labelOn}>
          <Input
            placeholder={labelOn}
            value={start}
            onChange={(event) => {
              const value = event.target.value;
              onChange?.({
                start: String(value),
                stop: String(stop),
              });
            }}
            type={'number'}
            min={0}
          />
        </FormItem>
      </div>
      <div className="min-w-[80px]">
        <FormItem label={labelOff}>
          <Input
            placeholder={labelOff}
            value={stop}
            onChange={(event) => {
              const value = event.target.value;
              onChange?.({
                start: String(start),
                stop: String(value),
              });
            }}
            type={'number'}
            min={0}
          />
        </FormItem>
      </div>
    </div>
  );
};
