import { Tunnel } from 'utils/device';
import { ITHConfigInput } from './ITHConfigInput';
import { selectDeviceSelected } from 'store/devices/devicesSlice';
import { useSelector } from 'react-redux';
import { useUpdateITHCVTunnelMutation } from 'store/devices/devicesApi';
import { useCallback, useEffect, useState } from 'react';
import { FullScreenLoader } from 'components/loader';
import Button from 'components/button';

type ITHCVState = Record<
  string, // id
  Tunnel
>;

function ITHConfigTypeSalidasTunel({ sectorId }: { sectorId: number }) {
  const deviceSelected = useSelector(selectDeviceSelected);
  const { serialNumber = '', tunnels = [] } = deviceSelected || {};
  const [updateITHCVTunnel, { isLoading: isUpdateITHCVTunnelLoading }] = useUpdateITHCVTunnelMutation();

  const [state, setState] = useState<ITHCVState>({});

  const tunnelsToUpdate = tunnels?.filter((tunnel: Tunnel) => {
    const localTunnel = state[tunnel.id];
    return (
      tunnel.fanQuantity !== localTunnel?.fanQuantity ||
      tunnel.electroValve1 !== localTunnel?.electroValve1 ||
      tunnel.electroValve2 !== localTunnel?.electroValve2 ||
      tunnel.pump1 !== localTunnel?.pump1 ||
      tunnel.pump2 !== localTunnel?.pump2 ||
      tunnel.fans !== localTunnel?.fans
    );
  });
  const hasChanges = tunnelsToUpdate.length > 0;

  const resetState = useCallback(() => {
    const localState = tunnels?.reduce((acc: ITHCVState, ithMode: Tunnel) => {
      acc[ithMode.id] = ithMode;
      return acc;
    }, {});
    setState(localState);
  }, [tunnels]);

  const onSave = useCallback(() => {
    tunnelsToUpdate.forEach((tunnel: Tunnel) => {
      const localTunnel = state[tunnel.id];
      updateITHCVTunnel({
        serialNumber,
        ...localTunnel,
      });
    });
  }, [serialNumber, state, tunnelsToUpdate, updateITHCVTunnel]);

  useEffect(() => {
    resetState();
  }, [resetState]);

  return (
    <div className={'flex flex-col gap-4'}>
      {isUpdateITHCVTunnelLoading && <FullScreenLoader />}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">
        {tunnels?.map((tunnel) => (
          <div key={sectorId} className="flex flex-col w-full gap-4 p-4 bg-neutral-900 rounded-md">
            <h3 className="text-lg font-semibold">Sector {tunnel.id}</h3>
            <div className="w-full grid grid-cols-2 lg:grid-cols-3 gap-2 sm:gap-6 md:gap-8">
              <ITHConfigInput
                label="Elect Valv 1"
                value={state[tunnel.id]?.electroValve1}
                onChange={(event) => {
                  setState({
                    ...state,
                    [tunnel.id]: {
                      ...state[tunnel.id],
                      electroValve1: Number(event.target.value),
                    },
                  });
                }}
              />
              <ITHConfigInput
                label="Elect Valv 2"
                value={state[tunnel.id]?.electroValve2}
                onChange={(event) => {
                  setState({
                    ...state,
                    [tunnel.id]: {
                      ...state[tunnel.id],
                      electroValve2: Number(event.target.value),
                    },
                  });
                }}
              />
              <ITHConfigInput
                label="Bomba 1"
                value={state[tunnel.id]?.pump1}
                onChange={(event) => {
                  setState({
                    ...state,
                    [tunnel.id]: {
                      ...state[tunnel.id],
                      pump1: Number(event.target.value),
                    },
                  });
                }}
              />
              <ITHConfigInput
                label="Bomba 2"
                value={state[tunnel.id]?.pump2}
                onChange={(event) => {
                  setState({
                    ...state,
                    [tunnel.id]: {
                      ...state[tunnel.id],
                      pump2: Number(event.target.value),
                    },
                  });
                }}
              />
              <ITHConfigInput
                label="Ventiladores"
                value={state[tunnel.id]?.fans}
                onChange={(event) => {
                  setState({
                    ...state,
                    [tunnel.id]: {
                      ...state[tunnel.id],
                      fans: Number(event.target.value),
                    },
                  });
                }}
              />
              <ITHConfigInput
                label="Cant de Vent"
                value={state[tunnel.id]?.fanQuantity}
                onChange={(event) => {
                  setState({
                    ...state,
                    [tunnel.id]: {
                      ...state[tunnel.id],
                      fanQuantity: Number(event.target.value),
                    },
                  });
                }}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="bg-background-black  px-4 py-3 justify-end flex gap-4 sm:px-6">
        <Button variant="text-danger" onClick={resetState} disabled={!hasChanges}>
          Cancelar
        </Button>
        <Button variant="fill" onClick={onSave} disabled={!hasChanges}>
          Guardar cambios
        </Button>
      </div>
    </div>
  );
}

export default ITHConfigTypeSalidasTunel;
