import { Recipe } from './event/diet';
import { DeviceStock } from './stock';
import { UnloadGuide, Lot } from './lot';
import { Silo } from './event/cas';

export const DEVICE_TYPE_SIP = 'SIP-N';
export const DEVICE_TYPE_ITH = 'ITH-C';
export const DEVICE_TYPE_ITH_CV = 'ITH-CV';
export const DEVICE_TYPE_CMAS = 'CMAS';
export const DEVICE_TYPE_GAC = 'GAC';
export const DEVICE_TYPE_CAS = 'CAS';
export const DEVICE_TYPE_AFIMILK = 'AFIMILKSIPN';
export const DEVICE_TYPE_DELPRO = 'DELPROSIPN';

export type DeviceType =
  | typeof DEVICE_TYPE_SIP
  | typeof DEVICE_TYPE_ITH
  | typeof DEVICE_TYPE_ITH_CV
  | typeof DEVICE_TYPE_CMAS
  | typeof DEVICE_TYPE_GAC
  | typeof DEVICE_TYPE_CAS
  | typeof DEVICE_TYPE_AFIMILK
  | typeof DEVICE_TYPE_DELPRO;

export type Device = {
  serialNumber: string;
  alias: string;
  type: DeviceType;
  sipnStock?: DeviceStock;
  averageFuelConsumptionPerKilometer?: any;
  availableFuelLiters?: number;
  fuelPrice?: number;
  associatedCmas?: any;
  fuelCostPerKilometer?: any;
  dryMatterModifiesRecipes?: boolean;
  fuelCostPerLitre?: number;
  mixerMaxCapacity?: number;
  recipes?: Recipe[];
  unloadGuides?: UnloadGuide[];
  lots?: Lot[];
  ver: string;
  lastDataRecievedDate?: string; // Formato ISO
  pumpUsers?: PumpUser[];
  pumpVehicles?: PumpVehicle[];
  fanTimers?: FanTimer[]; // ITH Timers
  associatedMilker?: Device;
  silos?: Silo[];
  // ITHCV
  animalSpotFans?: AnimalSpotFan[];
  animalSpotSprinklers?: AnimalSpotSprinkler[];
  cycleModes?: CycleMode[];
  ithModes?: ITHMode[];
  outletFans?: OutletFan[];
  outletSprinklers?: OutletSprinkler[];
  sectors?: ITHCVSector[];
  tunnels?: Tunnel[];
};

export type DeviceItem = Device & { link?: string; isSelected?: boolean };

export function isDeviceSIP({ type }: Device) {
  return type === DEVICE_TYPE_SIP;
}
export function isDeviceITH({ type }: Device) {
  return type === DEVICE_TYPE_ITH || DEVICE_TYPE_ITH_CV;
}
export function isDeviceCmas({ type }: Device) {
  return type === DEVICE_TYPE_CMAS;
}
export function isDeviceGac({ type }: Device) {
  return type === DEVICE_TYPE_GAC;
}
export function isDeviceCas({ type }: Device) {
  return type === DEVICE_TYPE_CAS;
}

export type PumpUser = {
  availableLiters: number;
  maxLiters: number;
  id: number;
  keyId: number;
  manualAccess: string;
  mode: string;
  name: string;
  password: string;
  pumpUserType: string;
};

export type PumpVehicle = {
  id: number;
  name: string;
  keyId?: string;
};

export type FanTimer = {
  id: '1' | '2' | '3' | '4' | '5' | '6' | '7';
  sector: '1' | '2';
  start: string;
  stop: string;
};

export type CycleMode = {
  id: string;
  fanOn: string;
  fanOff: string;
  sprinklerOn: string;
  sprinklerOff: string;
  cycle: 'none' | 'continuous' | 'independent';
};

export type ITHMode = {
  id: string;
  ithOn: number;
  ithOff: number;
  enable: 'none' | 'fanning' | 'sprinkling' | 'fanning+sprinkling';
};

export type OutletSprinkler = {
  id: number;
  electroValve: number;
  pump: number;
};

export type OutletFan = {
  id: number;
  fans: number;
  fanQuantity: number;
};

export type Tunnel = {
  id: number;
  fanQuantity: number;
  electroValve1: number;
  electroValve2: number;
  pump1: number;
  pump2: number;
  fans: number;
};

export type AnimalSpotSprinkler = {
  id: number;
  on: number;
  off: number;
  ithOn: number;
  ithOff: number;
  timers: Array<{ id: number; off: string; on: string }>;
};

export type AnimalSpotFan = {
  id: number;
  ithOn: number;
  ithOff: number;
  timers: Array<{ id: number; off: string; on: string }>;
};

export type ITHCVSector = {
  id: number;
  timers: Array<{ id: number; mode: number; ith: number; cycle: number; off: string; on: string }>;
};
