import Schedule from '@material-ui/icons/Schedule';
import { DatePickerComponent } from 'design-system/date-picker';
import { FormItem } from 'design-system/input';
import moment from 'moment';
import { useEffect, useState } from 'react';

const minPossibleTime = new Date();
const maxPossibleTime = new Date();
minPossibleTime.setHours(0, 0, 0, 0);
maxPossibleTime.setHours(23, 59, 59, 999);

const NumberedClockIcon = ({ number }: { number: number }) => (
  <div className="relative w-fit p-2 text-5xl bg-neutral-900 rounded-xl">
    <div className="absolute bottom-1 left-1 bg-neutral-900 text-white !text-lg rounded-full w-6 h-6 flex items-center justify-center">
      {number}
    </div>
    <Schedule fontSize="inherit" />
  </div>
);

export const ITHConfigClock = ({
  timerId,
  onChange,
  start,
  stop,
}: {
  timerId: number;
  start?: string;
  stop?: string;
  onChange?: (changes: any) => void;
}) => {
  const [startTime, setStartTime] = useState<Date | null>(minPossibleTime);
  const [endTime, setEndTime] = useState<Date | null>(maxPossibleTime);

  useEffect(() => {
    if (!start || !stop) return;

    const formattedStart = moment(start, 'HH:mm').toDate();
    const formattedStop = moment(stop, 'HH:mm').toDate();

    setStartTime(formattedStart);
    setEndTime(formattedStop);
  }, [start, stop]);

  return (
    <div className="flex items-center gap-1 w-full">
      <NumberedClockIcon number={timerId} />

      <div className="w-full grid sm:grid-cols-2 gap-4 p-3 bg-neutral-500/20 rounded-xl">
        <div className="min-w-[80px] ">
          <FormItem label="ON">
            <DatePickerComponent
              selected={startTime}
              onChange={(date: Date) => {
                setStartTime(date);
              }}
              onCalendarClose={() => {
                const savedFormattedStart = moment(start, 'HH:mm').toDate();
                if (startTime !== savedFormattedStart) {
                  const changes = {
                    start: moment(startTime).format('HH:mm'),
                    stop: moment(endTime).format('HH:mm'),
                  };
                  onChange?.(changes);
                }
              }}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={60}
              timeCaption="ON"
              dateFormat="HH:mm"
              timeFormat="HH:mm"
              minTime={minPossibleTime}
              maxTime={endTime || maxPossibleTime}
            />
          </FormItem>
        </div>
        <div className="min-w-[80px]">
          <FormItem label="OFF">
            <DatePickerComponent
              selected={endTime}
              onChange={(date: Date) => {
                setEndTime(date);
              }}
              onCalendarClose={() => {
                const savedFormattedStop = moment(stop, 'HH:mm').toDate();
                if (endTime !== savedFormattedStop) {
                  const changes = {
                    start: moment(startTime).format('HH:mm'),
                    stop: moment(endTime).format('HH:mm'),
                  };
                  onChange?.(changes);
                }
              }}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={60}
              timeCaption="OFF"
              dateFormat="HH:mm"
              timeFormat="HH:mm"
              minTime={startTime || minPossibleTime}
              maxTime={maxPossibleTime}
              injectTimes={[maxPossibleTime]}
            />
          </FormItem>
        </div>
      </div>
    </div>
  );
};
