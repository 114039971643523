import Button from 'components/button';
import { FullScreenLoader } from 'components/loader';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useUpdateITHCITHModeMutation } from 'store/devices/devicesApi';
import { selectDeviceSelected } from 'store/devices/devicesSlice';
import { ITHMode } from 'utils/device';
import { ITHConfigInput } from './ITHConfigInput';
import { ITHConfigSelect } from './ITHConfigSelect';

type ITHCVState = Record<
  string, // id
  ITHMode
>;

function ITHConfigTypeModoITH() {
  const deviceSelected = useSelector(selectDeviceSelected);
  const { serialNumber = '', ithModes = [] } = deviceSelected || {};
  const [updateITHCITHMode, { isLoading: isUpdateITHCITHModeLoading }] = useUpdateITHCITHModeMutation();

  const [state, setState] = useState<ITHCVState>({});

  const ithModesToUpdate = ithModes?.filter((ithMode: ITHMode) => {
    const localIthMode = state[ithMode.id];
    return (
      ithMode.ithOn !== localIthMode?.ithOn ||
      ithMode.ithOff !== localIthMode?.ithOff ||
      ithMode.enable !== localIthMode?.enable
    );
  });
  const hasChanges = ithModesToUpdate.length > 0;

  const resetState = useCallback(() => {
    const localState = ithModes?.reduce((acc: ITHCVState, ithMode: ITHMode) => {
      acc[ithMode.id] = ithMode;
      return acc;
    }, {});
    setState(localState);
  }, [ithModes]);

  const onSave = useCallback(() => {
    ithModesToUpdate.forEach((ithMode: ITHMode) => {
      const localCycle = state[ithMode.id];
      updateITHCITHMode({
        serialNumber,
        ...localCycle,
      });
    });
  }, [ithModesToUpdate, serialNumber, state, updateITHCITHMode]);

  useEffect(() => {
    resetState();
  }, [resetState]);

  return (
    <div className={'flex flex-col gap-4'}>
      {isUpdateITHCITHModeLoading && <FullScreenLoader />}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">
        {ithModes?.map((ithMode) => (
          <div className="flex flex-col w-full gap-4 p-4 bg-neutral-900 rounded-md">
            <h3 className="text-lg font-semibold">Sector {ithMode.id}</h3>
            <div className="w-full grid grid-cols-2 gap-2 sm:gap-6 md:gap-8">
              <ITHConfigInput
                label="ITH ON"
                value={state[ithMode.id]?.ithOn}
                onChange={(event) => {
                  setState({
                    ...state,
                    [ithMode.id]: {
                      ...state[ithMode.id],
                      ithOn: Number(event.target.value),
                    },
                  });
                }}
              />
              <ITHConfigInput
                label="ITH OFF"
                value={state[ithMode.id]?.ithOff}
                onChange={(event) => {
                  setState({
                    ...state,
                    [ithMode.id]: {
                      ...state[ithMode.id],
                      ithOff: Number(event.target.value),
                    },
                  });
                }}
              />
              <ITHConfigSelect
                label="Habilitar"
                onChangeValue={(enable) => {
                  setState({
                    ...state,
                    [ithMode.id]: {
                      ...state[ithMode.id],
                      enable: enable as 'none' | 'fanning' | 'sprinkling' | 'fanning+sprinkling',
                    },
                  });
                }}
                value={state[ithMode.id]?.enable || 'none'}
                options={[
                  { name: 'Ninguno', value: 'none' },
                  { name: 'Aspersión', value: 'sprinkling' },
                  { name: 'Ventilación', value: 'fanning' },
                  { name: 'Aspersión y Ventilación', value: 'fanning+sprinkling' },
                ]}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="bg-background-black  px-4 py-3 justify-end flex gap-4 sm:px-6">
        <Button variant="text-danger" onClick={resetState} disabled={!hasChanges}>
          Cancelar
        </Button>
        <Button variant="fill" onClick={onSave} disabled={!hasChanges}>
          Guardar cambios
        </Button>
      </div>
    </div>
  );
}

export default ITHConfigTypeModoITH;
