import { FormItem, Input } from 'design-system/input';

export const ITHConfigInput = ({
  onBlur,
  label,
  defaultValue,
  value,
  onChange,
}: {
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  label: string;
  defaultValue?: number;
  value?: number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => (
  <div className="p-3 bg-neutral-500/20 rounded-xl w-full">
    <FormItem label={label}>
      <Input
        placeholder={label}
        defaultValue={defaultValue}
        onBlur={onBlur}
        type={'number'}
        min={0}
        value={value}
        onChange={onChange}
      />
    </FormItem>
  </div>
);
