import Button from 'components/button';
import { FullScreenLoader } from 'components/loader';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useUpdateITHCVCycleModeMutation } from 'store/devices/devicesApi';
import { selectDeviceSelected } from 'store/devices/devicesSlice';
import { CycleMode } from 'utils/device';
import { ITHConfigClock } from './ITHConfigClock';
import { ITHConfigSelect } from './ITHConfigSelect';

type ITHCVState = Record<
  string, // id
  CycleMode
>;

function ITHConfigTypeModoCiclado() {
  const deviceSelected = useSelector(selectDeviceSelected);
  const { serialNumber = '', cycleModes = [] } = deviceSelected || {};
  const [updateITHCVCycle, { isLoading: isUpdateITHCVCycleLoading }] = useUpdateITHCVCycleModeMutation();

  const [state, setState] = useState<ITHCVState>({});

  const cyclesToUpdate = cycleModes?.filter((cycleMode: CycleMode) => {
    const localCycle = state[cycleMode.id];
    return (
      cycleMode.fanOn !== localCycle?.fanOn ||
      cycleMode.fanOff !== localCycle?.fanOff ||
      cycleMode.sprinklerOn !== localCycle?.sprinklerOn ||
      cycleMode.sprinklerOff !== localCycle?.sprinklerOff ||
      cycleMode.cycle !== localCycle?.cycle
    );
  });
  const hasChanges = cyclesToUpdate.length > 0;

  const resetState = useCallback(() => {
    const localState = cycleModes?.reduce((acc: ITHCVState, cycleMode: CycleMode) => {
      acc[cycleMode.id] = cycleMode;
      return acc;
    }, {});
    setState(localState);
  }, [cycleModes]);

  const onSave = useCallback(() => {
    cyclesToUpdate.forEach((cycleMode: CycleMode) => {
      const localCycle = state[cycleMode.id];
      updateITHCVCycle({
        serialNumber,
        ...localCycle,
      });
    });
  }, [serialNumber, state, cyclesToUpdate, updateITHCVCycle]);

  useEffect(() => {
    resetState();
  }, [resetState]);

  return (
    <div className={'flex flex-col gap-4'}>
      {isUpdateITHCVCycleLoading && <FullScreenLoader />}
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-4 md:gap-8">
        {cycleModes?.map((cycleMode) => (
          <div className="flex flex-col w-full gap-4 p-4 bg-neutral-900 rounded-md">
            <h3 className="text-lg font-semibold">Sector {cycleMode.id}</h3>
            <div className="w-full grid grid-cols-2 gap-2 sm:gap-6 md:gap-8">
              <div className="w-full sm:w-auto flex flex-col">
                <h3 className="text-lg font-semibold">Ventilación</h3>
                <ITHConfigClock
                  timerId={Number(cycleMode.id)}
                  start={state[cycleMode.id]?.fanOn}
                  stop={state[cycleMode.id]?.fanOff}
                  onChange={({ start, stop }) => {
                    setState({
                      ...state,
                      [cycleMode.id]: {
                        ...state[cycleMode.id],
                        fanOn: start,
                        fanOff: stop,
                      },
                    });
                  }}
                />
              </div>
              <div className="w-full sm:w-auto flex flex-col">
                <h3 className="text-lg font-semibold">Aspersión</h3>
                <ITHConfigClock
                  timerId={Number(cycleMode.id)}
                  start={state[cycleMode.id]?.sprinklerOn}
                  stop={state[cycleMode.id]?.sprinklerOff}
                  onChange={({ start, stop }) => {
                    setState({
                      ...state,
                      [cycleMode.id]: {
                        ...state[cycleMode.id],
                        sprinklerOn: start,
                        sprinklerOff: stop,
                      },
                    });
                  }}
                />
              </div>
              <ITHConfigSelect
                label="Ciclo"
                onChangeValue={(cycle) => {
                  setState({
                    ...state,
                    [cycleMode.id]: {
                      ...state[cycleMode.id],
                      cycle: cycle as 'none' | 'continuous' | 'independent',
                    },
                  });
                }}
                value={state[cycleMode.id]?.cycle || 'none'}
                options={[
                  { name: 'Ninguno', value: 'none' },
                  { name: 'Continuo', value: 'continuous' },
                  { name: 'Independiente', value: 'independent' },
                ]}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="bg-background-black  px-4 py-3 justify-end flex gap-4 sm:px-6">
        <Button variant="text-danger" onClick={resetState} disabled={!hasChanges}>
          Cancelar
        </Button>
        <Button variant="fill" onClick={onSave} disabled={!hasChanges}>
          Guardar cambios
        </Button>
      </div>
    </div>
  );
}

export default ITHConfigTypeModoCiclado;
