import Button from 'components/button';
import { FullScreenLoader } from 'components/loader';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useUpdateITHCVAnimalSpotSprinklerMutation } from 'store/devices/devicesApi';
import { selectDeviceSelected } from 'store/devices/devicesSlice';
import { AnimalSpotSprinkler } from 'utils/device';
import { ITHConfigClock } from './ITHConfigClock';
import { ITHConfigClockRango } from './ITHConfigClockRango';

function ITHConfigTypeCamasAsp({ sectorId }: { sectorId: number }) {
  const deviceSelected = useSelector(selectDeviceSelected);
  const { serialNumber = '', animalSpotSprinklers = [] } = deviceSelected || {};
  const [updateITHCVAnimalSpotSprinkler, { isLoading: isUpdateITHCVAnimalSpotSprinklerLoading }] =
    useUpdateITHCVAnimalSpotSprinklerMutation();

  const [state, setState] = useState<AnimalSpotSprinkler | null>(null);

  const targetAnimalSpotSprinkler =
    animalSpotSprinklers.find((animalSpotSprinkler) => animalSpotSprinkler.id === sectorId) || null;

  const hasChanges =
    targetAnimalSpotSprinkler?.ithOff !== state?.ithOff ||
    targetAnimalSpotSprinkler?.ithOn !== state?.ithOn ||
    targetAnimalSpotSprinkler?.on !== state?.on ||
    targetAnimalSpotSprinkler?.off !== state?.off ||
    targetAnimalSpotSprinkler?.timers?.some((timer) => {
      return (
        timer.on !== state?.timers?.find((t) => t.id === timer.id)?.on ||
        timer.off !== state?.timers?.find((t) => t.id === timer.id)?.off
      );
    });

  const resetState = useCallback(() => {
    setState(targetAnimalSpotSprinkler);
  }, [targetAnimalSpotSprinkler]);

  const onSave = useCallback(() => {
    if (state && hasChanges) {
      updateITHCVAnimalSpotSprinkler({
        serialNumber,
        ...state,
      });
    }
  }, [hasChanges, serialNumber, state, updateITHCVAnimalSpotSprinkler]);

  useEffect(() => {
    resetState();
  }, [resetState]);

  return (
    <div className={'flex flex-col gap-4'}>
      {isUpdateITHCVAnimalSpotSprinklerLoading && <FullScreenLoader />}
      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-2 sm:gap-6 md:gap-8">
        {targetAnimalSpotSprinkler?.timers?.map((targetTimer) => (
          <ITHConfigClock
            timerId={Number(targetTimer.id)}
            start={state?.timers?.find((timer) => timer.id === targetTimer.id)?.on}
            stop={state?.timers?.find((timer) => timer.id === targetTimer.id)?.off}
            onChange={({ start, stop }) => {
              if (!state) return;
              setState({
                ...state,
                timers: state.timers.map((timer) => {
                  if (timer.id === targetTimer.id) {
                    return {
                      ...timer,
                      on: start,
                      off: stop,
                    };
                  }
                  return timer;
                }),
              });
            }}
          />
        ))}
      </div>

      <div className="px-4 py-5 tablet:grid tablet:grid-cols-3 tablet:gap-4 tablet:px-6 items-center">
        <dt className="text-sm font-medium ">Rango de trabajo ITH</dt>
        <dd className="mt-1 text-sm tablet:col-span-2 tablet:mt-0">
          <ITHConfigClockRango
            labelOn="ITH ON"
            labelOff="ITH OFF"
            start={state?.ithOn}
            stop={state?.ithOff}
            onChange={(changes: any) => {
              if (!state) return;
              setState({
                ...state,
                ithOn: Number(changes.start),
                ithOff: Number(changes.stop),
              });
            }}
          />
        </dd>
      </div>
      <div className="px-4 py-5 tablet:grid tablet:grid-cols-3 tablet:gap-4 tablet:px-6 items-center">
        <dt className="text-sm font-medium ">Tiempo de Ciclado</dt>
        <dd className="mt-1 text-sm tablet:col-span-2 tablet:mt-0">
          <ITHConfigClockRango
            labelOn="Encendido"
            labelOff="Apagado"
            start={state?.on}
            stop={state?.off}
            onChange={(changes: any) => {
              if (!state) return;
              setState({
                ...state,
                on: Number(changes.start),
                off: Number(changes.stop),
              });
            }}
          />
        </dd>
      </div>
      <div className="bg-background-black  px-4 py-3 justify-end flex gap-4 sm:px-6">
        <Button variant="text-danger" onClick={resetState} disabled={!hasChanges}>
          Cancelar
        </Button>
        <Button variant="fill" onClick={onSave} disabled={!hasChanges}>
          Guardar cambios
        </Button>
      </div>
    </div>
  );
}

export default ITHConfigTypeCamasAsp;
