import { OutletFan } from 'utils/device';
import { ITHConfigInput } from './ITHConfigInput';
import { useUpdateITHCVOutletFanMutation } from 'store/devices/devicesApi';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectDeviceSelected } from 'store/devices/devicesSlice';
import Button from 'components/button';
import { FullScreenLoader } from 'components/loader';

type ITHCVState = Record<
  string, // id
  OutletFan
>;

function ITHConfigTypeSalidasCamasVentiladores() {
  const deviceSelected = useSelector(selectDeviceSelected);
  const { serialNumber = '', outletFans = [] } = deviceSelected || {};
  const [updateITHCVOutletFan, { isLoading: isUpdateITHCVOutletFanLoading }] = useUpdateITHCVOutletFanMutation();

  const [state, setState] = useState<ITHCVState>({});

  const outletFansToUpdate = outletFans?.filter((outletFan: OutletFan) => {
    const localOutletFan = state[outletFan.id];
    return outletFan.fans !== localOutletFan?.fans || outletFan.fanQuantity !== localOutletFan?.fanQuantity;
  });
  const hasChanges = outletFansToUpdate.length > 0;

  const resetState = useCallback(() => {
    const localState = outletFans?.reduce((acc: ITHCVState, outletFan: OutletFan) => {
      acc[outletFan.id] = outletFan;
      return acc;
    }, {});
    setState(localState);
  }, [outletFans]);

  const onSave = useCallback(() => {
    outletFansToUpdate.forEach((outletFan: OutletFan) => {
      const localOutletFan = state[outletFan.id];
      updateITHCVOutletFan({
        serialNumber,
        ...localOutletFan,
      });
    });
  }, [serialNumber, state, outletFansToUpdate, updateITHCVOutletFan]);

  useEffect(() => {
    resetState();
  }, [resetState]);

  return (
    <div className={'flex flex-col gap-4'}>
      {isUpdateITHCVOutletFanLoading && <FullScreenLoader />}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">
        {outletFans?.map((outletFan) => (
          <div className="flex flex-col w-full gap-4 p-4 bg-neutral-900 rounded-md">
            <h3 className="text-lg font-semibold">Sector {outletFan.id}</h3>
            <div className="w-full grid grid-cols-2 gap-2 sm:gap-6 md:gap-8">
              <ITHConfigInput
                label="Ventiladores"
                value={state[outletFan.id]?.fans}
                onChange={(event) => {
                  setState({
                    ...state,
                    [outletFan.id]: {
                      ...state[outletFan.id],
                      fans: Number(event.target.value),
                    },
                  });
                }}
              />
              <ITHConfigInput
                label="Cant de Vent"
                value={state[outletFan.id]?.fanQuantity}
                onChange={(event) => {
                  setState({
                    ...state,
                    [outletFan.id]: {
                      ...state[outletFan.id],
                      fanQuantity: Number(event.target.value),
                    },
                  });
                }}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="bg-background-black  px-4 py-3 justify-end flex gap-4 sm:px-6">
        <Button variant="text-danger" onClick={resetState} disabled={!hasChanges}>
          Cancelar
        </Button>
        <Button variant="fill" onClick={onSave} disabled={!hasChanges}>
          Guardar cambios
        </Button>
      </div>
    </div>
  );
}

export default ITHConfigTypeSalidasCamasVentiladores;
