import FormCard, { FormCardIcon } from 'design-system/form-card';
import { IconConfig } from 'design-system/icon';
import Select from 'design-system/select';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectDeviceSelected } from 'store/devices/devicesSlice';
import ITHConfigTypeCamasAsp from './ITHConfigTypeCamasAsp';
import ITHConfigTypeCamasVent from './ITHConfigTypeCamasVent';
import ITHConfigTypeModoCiclado from './ITHConfigTypeModoCiclado';
import ITHConfigTypeModoITH from './ITHConfigTypeModoITH';
import ITHConfigTypeSalidasCamasAspersores from './ITHConfigTypeSalidasCamasAspersores';
import ITHConfigTypeSalidasCamasVentiladores from './ITHConfigTypeSalidasCamasVentiladores';
import ITHConfigTypeTunel from './ITHConfigTypeSalidasTunel';
import ITHConfigTypeSector from './ITHConfigTypeSector';

const minPossibleTime = new Date();
const maxPossibleTime = new Date();
minPossibleTime.setHours(0, 0, 0, 0);
maxPossibleTime.setHours(23, 59, 59, 999);

function ITHConfigSectores() {
  const deviceSelected = useSelector(selectDeviceSelected);
  const { serialNumber = '' } = deviceSelected || {};

  const ithSectoresOptions = [1, 2, 3, 4]
    .map((sector) => ({
      value: `Sector ${sector}`,
      name: `Sector ${sector}`,
      number: sector,
      type: 'Sector',
    }))
    .concat(
      [1, 2, 3, 4].map((animalSpotFan) => ({
        value: `Cama Vent ${animalSpotFan}`,
        name: `Cama Vent ${animalSpotFan}`,
        number: animalSpotFan,
        type: 'CamaVent',
      }))
    )
    .concat(
      [1, 2, 3, 4].map((animalSpotSprinkler) => ({
        value: `Cama Asp ${animalSpotSprinkler}`,
        name: `Cama Asp ${animalSpotSprinkler}`,
        number: animalSpotSprinkler,
        type: 'CamaAsp',
      }))
    )
    .concat([
      { value: 'Salidas Túnel', name: 'Salidas Túnel', type: 'SalidasTunel', number: 0 },
      {
        value: 'Salidas Camas Ventiladores',
        name: 'Salidas Camas Ventiladores',
        type: 'SalidasCamasVentiladores',
        number: 0,
      },
      {
        value: 'Salidas Camas Aspersores',
        name: 'Salidas Camas Aspersores',
        type: 'SalidasCamasAspersores',
        number: 0,
      },
      { value: 'Modos Ciclado', name: 'Modos Ciclado', type: 'ModosCiclado', number: 0 },
      { value: 'Modos ITH', name: 'Modos ITH', type: 'ModosITH', number: 0 },
    ]);

  const [sector, setSector] = useState<string>(ithSectoresOptions[0].value);
  const sectorObj = ithSectoresOptions.find((option) => option.value === sector);
  const sectorName = sectorObj?.name;
  const sectorNumber = sectorObj?.number || 0;
  const sectorType = sectorObj?.type;

  return (
    <div className="flex flex-col">
      <div className="content-view px-8 py-8 flex flex-col gap-3">
        <FormCard
          title={`Configuración ITH - ${sectorName}`}
          subtitle={`${serialNumber}`}
          icon={
            <FormCardIcon className="px-2 py-2">
              <IconConfig />
            </FormCardIcon>
          }
          items={[
            {
              value: (
                <div className="w-full sm:w-72">
                  <Select
                    label="Elegir Sector"
                    options={ithSectoresOptions || []}
                    onChangeValue={(value) => {
                      setSector(value);
                    }}
                    value={sector}
                  />
                </div>
              ),
            },
            ...(sectorType === 'Sector'
              ? [{ value: <ITHConfigTypeSector key={`sector - ${sectorNumber}`} sectorId={sectorNumber} /> }]
              : []),
            ...(sectorType === 'CamaVent'
              ? [{ value: <ITHConfigTypeCamasVent key={`camaVent - ${sectorNumber}`} sectorId={sectorNumber} /> }]
              : []),
            ...(sectorType === 'CamaAsp'
              ? [{ value: <ITHConfigTypeCamasAsp key={`camaAsp - ${sectorNumber}`} sectorId={sectorNumber} /> }]
              : []),
            ...(sectorType === 'SalidasTunel' ? [{ value: <ITHConfigTypeTunel sectorId={sectorNumber} /> }] : []),
            ...(sectorType === 'SalidasCamasAspersores' ? [{ value: <ITHConfigTypeSalidasCamasAspersores /> }] : []),
            ...(sectorType === 'SalidasCamasVentiladores'
              ? [{ value: <ITHConfigTypeSalidasCamasVentiladores /> }]
              : []),
            ...(sectorType === 'ModosITH' ? [{ value: <ITHConfigTypeModoITH /> }] : []),
            ...(sectorType === 'ModosCiclado' ? [{ value: <ITHConfigTypeModoCiclado /> }] : []),
          ]}
        />
      </div>
    </div>
  );
}

export default ITHConfigSectores;
