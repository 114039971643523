import Button from 'components/button';
import { FullScreenLoader } from 'components/loader';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useUpdateITHCVAnimalSpotFanMutation } from 'store/devices/devicesApi';
import { selectDeviceSelected } from 'store/devices/devicesSlice';
import { AnimalSpotFan } from 'utils/device';
import { ITHConfigClock } from './ITHConfigClock';
import { ITHConfigClockRango } from './ITHConfigClockRango';

function ITHConfigTypeCamasVent({ sectorId }: { sectorId: number }) {
  const deviceSelected = useSelector(selectDeviceSelected);
  const { serialNumber = '', animalSpotFans = [] } = deviceSelected || {};
  const [updateITHCVAnimalSpotFan, { isLoading: isUpdateITHCVAnimalSpotFanLoading }] =
    useUpdateITHCVAnimalSpotFanMutation();

  const [state, setState] = useState<AnimalSpotFan | null>(null);

  const targetAnimalSpotFan = animalSpotFans.find((animalSpotFan) => animalSpotFan.id === sectorId) || null;

  const hasChanges =
    targetAnimalSpotFan?.ithOff !== state?.ithOff ||
    targetAnimalSpotFan?.ithOn !== state?.ithOn ||
    targetAnimalSpotFan?.timers?.some((timer) => {
      return (
        timer.on !== state?.timers?.find((t) => t.id === timer.id)?.on ||
        timer.off !== state?.timers?.find((t) => t.id === timer.id)?.off
      );
    });

  const resetState = useCallback(() => {
    setState(targetAnimalSpotFan);
  }, [targetAnimalSpotFan]);

  const onSave = useCallback(() => {
    if (state && hasChanges) {
      updateITHCVAnimalSpotFan({
        serialNumber,
        ...state,
      });
    }
  }, [hasChanges, serialNumber, state, updateITHCVAnimalSpotFan]);

  useEffect(() => {
    resetState();
  }, [resetState]);

  return (
    <div className={'flex flex-col gap-4'}>
      {isUpdateITHCVAnimalSpotFanLoading && <FullScreenLoader />}
      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-2 sm:gap-6 md:gap-8">
        {targetAnimalSpotFan?.timers?.map((targetTimer) => (
          <ITHConfigClock
            timerId={Number(targetTimer.id)}
            start={state?.timers?.find((timer) => timer.id === targetTimer.id)?.on}
            stop={state?.timers?.find((timer) => timer.id === targetTimer.id)?.off}
            onChange={({ start, stop }) => {
              if (!state) return;
              setState({
                ...state,
                timers: state.timers.map((timer) => {
                  if (timer.id === targetTimer.id) {
                    return {
                      ...timer,
                      on: start,
                      off: stop,
                    };
                  }
                  return timer;
                }),
              });
            }}
          />
        ))}
      </div>

      <div className="px-4 py-5 tablet:grid tablet:grid-cols-3 tablet:gap-4 tablet:px-6 items-center">
        <dt className="text-sm font-medium ">Rango de trabajo ITH</dt>
        <dd className="mt-1 text-sm tablet:col-span-2 tablet:mt-0">
          <ITHConfigClockRango
            labelOn="ITH ON"
            labelOff="ITH OFF"
            start={state?.ithOn}
            stop={state?.ithOff}
            onChange={(changes: any) => {
              if (!state) return;
              setState({
                ...state,
                ithOn: Number(changes.start),
                ithOff: Number(changes.stop),
              });
            }}
          />
        </dd>
      </div>
      <div className="bg-background-black  px-4 py-3 justify-end flex gap-4 sm:px-6">
        <Button variant="text-danger" onClick={resetState} disabled={!hasChanges}>
          Cancelar
        </Button>
        <Button variant="fill" onClick={onSave} disabled={!hasChanges}>
          Guardar cambios
        </Button>
      </div>
    </div>
  );
}

export default ITHConfigTypeCamasVent;
